<template>
    <div style="width: 100%;">
        <div style="display: flex;align-items: center;width: 87.5rem;margin: 0 auto">
            <img src="@/assets/img/logo.png" style="width: 12.5rem;height: 2.375rem;margin-right: 2rem">
            <p_menu></p_menu>
            <div style="display: flex;align-items: center;gap: 1rem;">
                <i class="el-icon-search" style="font-size: 1.25rem;cursor: pointer"></i>
                <i @click="goMine" class="el-icon-user-solid" style="font-size: 1.25rem;cursor: pointer"></i>
            </div>
        </div>
    </div>
</template>

<script>
import p_menu from "@/components/p_menu/index.vue";
export default {
    components: {
        p_menu
    },
    props: {},
    data() {
        return {}
    },
    created() {
    },
    mounted() {

    },
    methods: {
        goMine(){
            // const routeUrl = this.$router.resolve({
            //     path: '/mine',
            // })
            //
            // window.open(routeUrl.href, '_blank')

            window.open('https://test.buildingsmartchina.org/web/uc', '_blank');
        },
    }
}
</script>

<style scoped>

</style>
